import React from 'react';
import { useState, useEffect } from 'react';

import useWindowDimensions from './Listeners/WindowSize.jsx';

import webData from '../Resources/webData.json';




const EventsList = () => {

    const eventsData = webData.events;

    const eventList = eventsData.map((item, index) => (
        <Event data={item} key={index} />
    ));

    return (
        <div className="events">

            {eventList}

        </div>
    );
}



const Event = ({ data }) => {


    const [currentStyle, setCurrentStyle] = useState("event-banner");
    const { width, height } = useWindowDimensions();

    if ((width > 1024) && (currentStyle != "event-box")) {
        setCurrentStyle("event-box");
    } else if ((width <= 1024) && (currentStyle != "event-banner")) {
        setCurrentStyle("event-banner");
    }


    return (
        <div className={"event " + currentStyle}>
            <div className="date-wrapper">
                <div className="date">
                    <div className="day-topper strong">{data.alphabeticalDayShort}</div>
                    <div className="date-main strong">{data.numericalDay}</div>
                </div>
            </div>

            <div className="content">
                <div className="information">
                    <div>
                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                        <p>{data.address}</p>
                    </div>
                    <div>
                        <svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                        <p>{data.specificDate}</p>
                    </div>
                </div>

                <div className="text">
                    <div className="title strong" role="heading">{data.title}</div>
                    <p>{data.description}</p>
                </div>

                <div className="banner-line"></div>

            </div>

            <div className="img-wrapper">
                <img alt={data.alt} src={ `${data.image}` }></img>
                <div className="mask"></div>
            </div>

        </div>
    );
}



export default EventsList;