import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import '../Resources/CSS/home.css';

import EventsList from "../Components/EventsList.jsx";
import TestimonialCards from "../Components/TestimonialCards.jsx";
import ConnectPopup from "../Components/ConnectPopup.jsx";

import attaboy_wordmark from '../Resources/Graphics/Logos/PNG/AttaBoy_Wordmark.png';
import img_notifications_iphone from '../Resources/Graphics/Notifications - iPhone.png';
import footer_banner from '../Resources/Graphics/img_Footer_Banner_Home.png';


function Home() {

    const [mobileMenu, setMobileMenu] = useState("");
    const [popupState, setPopupState] = useState("hide")

    function toggleMobileMenu() {
        mobileMenu == "" ? setMobileMenu("open") : setMobileMenu("");
    }


    return (
        <div className="Home Page">

            <ConnectPopup state={popupState} setState={setPopupState} />
            


            <header>
                <div className="nav-container cap-end-margin">

                    <a className="wordmark-link-wrapper" href="/"><img alt="AttaBoy Networking wordmark logo" className="wordmark" src={attaboy_wordmark} /></a>

                    <nav className="nav-links">
                        <a role="link" href="/">Home</a>
                        <a role="link" href="/about">About</a>
                        <button className="connect connect-button-small" onClick={() => setPopupState("actions")}>Connect</button>
                    </nav>

                    <div className={"mobile-menu " + mobileMenu}>

                        <svg role="menu" className="bars" onClick={() => toggleMobileMenu()} width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 24H42M6 12H42M6 36H42" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                        <svg role="menu" className="x" onClick={() => toggleMobileMenu()} width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M36 12L12 36M12 12L36 36" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                        <div className="menu">
                            <ul>
                                <li><a role="link" className="strong" href="/">Home</a></li>
                                <li><a role="link" href="/about">About</a></li>
                                <li><a role="button" onClick={() => {setPopupState("actions"); toggleMobileMenu()}}>Connect</a></li>
                            </ul>
                        </div>

                    </div>

                </div>
            </header>



            <section className="section-top-hero">

                <HashLink class="accessibility-skip" smooth to='#main-content' >Skip to Main Content</HashLink>

                <div className="top-title-card">
                    <h1 className="strong">We make student <br/> networking easy</h1>
                    <div className="strong">Expand your circle</div>
                </div>

                <div className="elipses">
                    <svg role="presentation" className="ultrathin-circle" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="-100" cy="900" r="600" fill="none" stroke="#EF719E" strokeWidth="1" />
                        <circle cx="1800" cy="500" r="600" fill="none" stroke="#EF719E" strokeWidth="1" />
                        <circle cx="1000" cy="1200" r="600" fill="none" stroke="#EF719E" strokeWidth="1" />
                    </svg> 
                </div>

            </section>

            <section className="section-summary special-tab-plugin rounded-tab-plugin" id="main-content">

                <div className="rounded-tab">
                    <div className="tab"></div>
                </div>

                <div className="inline-content">



                    <div className="stats-grid-container remote-tab-spacer">

                        <div>
                            <h2 className="strong">1,000+</h2>
                            <h3 className="strong">Impressions In 2 Weeks</h3>
                        </div>

                        <div>
                            <h2 className="strong">2,000+</h2>
                            <h3 className="strong">Combined Following</h3>
                        </div>

                        <div>
                            <h2 className="strong">200+</h2>
                            <h3 className="strong">Followers In 30 Days</h3>
                        </div>

                        <div>
                            <h2 className="strong">85%</h2>
                            <h3 className="strong">Jobs By Referrals</h3>
                        </div>

                    </div>



                    <div className="feature-content-container">

                    <h2>Build Your <br/> Network</h2>
                    <img alt="iPhone with WhatsApp notification" src={img_notifications_iphone}></img>

                    </div>

                    <div className="about-content-container">

                    <h2>Who We Are</h2>
                    <h3>
                        AttaBoy Networking welcomes a dynamic community 
                        <br/>
                        of ambitious <span> students and entrepreneurs</span> in the small 
                        <br/>
                        business sector, united by a shared passion for making 
                        <br/>
                        a positive impact in the world.
                    </h3>
                    <button className="connect connect-button" onClick={() => setPopupState("actions")}>Connect</button>

                    </div>



                </div>

            </section>



            <section className="section-components rounded-tab-plugin">

                <div className="rounded-tab">
                    <div className="tab"></div>
                </div>

                <div className="scrolling-testimonials-container remote-tab-spacer">

                    <div className="inline-content mobile-inline-margin-spacer">

                        <h2 className="gradient-header">Success Stories</h2>

                    </div>



                    <TestimonialCards />

                </div>



                <div className="upcoming-events-container">

                    <div className="inline-content mobile-inline-margin-spacer">

                        <div className="events-header">
                            <h2 className="gradient-header">Upcoming Events</h2>
                            <div className="date-divider">
                                <h3 className="strong">August 2024</h3>
                                <div></div>
                            </div>
                        </div>

                    </div>


                    <EventsList />

                </div>

            </section>



            <footer>

                <div className="cta-hero-card">
                    <div className="text">
                        <h2>Join Us<br/>Today</h2>
                        <button className="connect connect-button" onClick={() => setPopupState("actions")}>Connect</button>
                    </div>
                    <img alt="Person smiling" src={footer_banner} />
                    <div className="mask"></div>
                </div>



                <div className="main cap-end-margin">

                    <div className="above-divider">

                        <a className="wordmark-link-wrapper" href="/"><img alt="AttaBoy Networking wordmark logo" className="wordmark" src={attaboy_wordmark} /></a>

                        <div className="nav-container">
                            <div className="nav nav-links">
                                <a role="link" href="/">Home</a>
                                <a role="link" href="/about">About</a>
                                <button className="connect connect-button-small" onClick={() => setPopupState("actions")}>Connect</button>
                            </div>

                            <div className="social-media">
                                <a target="_blank" role="link" aria-label="Facebook" href="https://www.facebook.com/AttaBoyNetworking/"><svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/></svg></a>
                                <a target="_blank" role="link" aria-label="LinkedIn" href="https://www.linkedin.com/company/attaboynetworking/"><svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg></a>
                                <a target="_blank" role="link" aria-label="Instagram" href="https://www.instagram.com/attaboynetworking/"><svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z"/></svg></a>
                                <a target="_blank" role="link" aria-label="YouTube" href="https://www.youtube.com/channel/UCSE03qfY7uG7Qaap9cXOO4w"><svg role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M282 256.2l-95.2-54.1V310.3L282 256.2zM384 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm14.4 136.1c7.6 28.6 7.6 88.2 7.6 88.2s0 59.6-7.6 88.1c-4.2 15.8-16.5 27.7-32.2 31.9C337.9 384 224 384 224 384s-113.9 0-142.2-7.6c-15.7-4.2-28-16.1-32.2-31.9C42 315.9 42 256.3 42 256.3s0-59.7 7.6-88.2c4.2-15.8 16.5-28.2 32.2-32.4C110.1 128 224 128 224 128s113.9 0 142.2 7.7c15.7 4.2 28 16.6 32.2 32.4z"/></svg></a>
                            </div>
                        </div>

                    </div>



                    <div className="below-divider">

                        <p>© 2024 AttaBoy Networking All Rights Reserved</p>

                    </div>

                </div>



            </footer>



        </div>
    );
}

export default Home;