import React from 'react';

import webData from '../Resources/webData.json';








const TestimonialCards = () => {

    const stories = webData.stories;

    const testimonialCardsList = stories.map((item, index) => (
        <TestimonialCard data={item} key={index} />
    ));


    return (
        <div className="scrolling-testimonials">

            {testimonialCardsList}

        </div>
    );
}





const TestimonialCard = ({ data }) => {

    return (
        <div className="testimonial">
            <img alt={data.alt} src={data.image} />
            <div className="text">
                <a target="_blank" role="link" href={"https://" + data.link}>{data.link}</a>
                <div className="title strong" role="heading">{data.title}</div>
                <p>"{data.description}"</p>
            </div>
        </div>
    );
}



export default TestimonialCards;