import React from 'react';
import './App.css';
import './Resources/CSS/basic.css';

import { Routes, BrowserRouter, Route } from "react-router-dom";

import Home from './Pages/Home.jsx';
import About from './Pages/About.jsx';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
      
        <Routes>

          <Route
            path="/"
            element={<Home />}
          />

          <Route
            path="/about"
            element={<About />}
          />

        </Routes>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
